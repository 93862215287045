.nav-pills {

	.nav-link {
		text-align: left;
		padding-left: $spacer * 2;
		color: $black;
	}

	.nav-link.active,
	.show>.nav-link {
		font-weight: bold;
		padding-left: $spacer * 1.5
	}



	.nav-link.active,
	::before {
		border-left: 0.5rem;
		border-left-color: $quaternary;
		border-left-style: solid;
	}

}

@include media-breakpoint-up(lg) {

	nav {
		.nav-link.active,
		.nav-top-link:hover {

			box-shadow: inset 0 -5px 0 0 $secondary;
		}
	}

	.tab-pane {
		.nav-item:hover {
			box-shadow: inset 0 -5px 0 0 $tertiary !important;
		}
	}

	.nav-tab-content{
		height: $spacer * 3.5;
	}
	.border-xl-0 {
		border: none !important;
	}
}

.tab-pane {
	.nav-item:hover {
		ul {
			display: flex;
		}
	}
}

.menu-bottom-basic:hover {
	ul {
		display: grid !important;
	}
}


.nav-item {
	a {
		color: $dark;
	}

	.view-link {
		color: $primary;

		&:hover {
			text-decoration: none;
		}

		;

	}
}

@include media-breakpoint-down(lg) {

	.navbar-brand {
		img {
			height: 58px;
		}
	}

	.navbar-nav {
		width: 100%;

		.nav-item {
			border-bottom: 1px solid $gray-300;
		}
	}

	.showBottom {
		display: flex !important;

	}

	.dropdown-menu {
		border: none;

		li {
			border-top: $border;
			padding-top: $spacer;
			padding-bottom: $spacer;
		}
		// li:last-child() {
		// 	padding
		// }
	}

	.nav-link {
		font-family: $headings-font-family;
	}
}

.zip-btn {
	font-family: $font-family-base;
	font-weight: $lead-font-weight;

	&:hover {
		background-color: initial;
		color: $black;

		svg,
		svg * {
			fill: $black !important;
		}
	}
}

.dropdown-menu {
	.nav-link {
		&:hover {
			text-decoration: underline;
			color: $primary;
		}
	}

	a {
		text-decoration: none;

		&:hover {
			text-decoration: underline;
			color: $primary;
		}

	}

	.view-link {
		text-decoration: underline;
	}

	.view-doc {
		text-decoration: underline;
		color: $primary;

		&:hover {
			text-decoration: none;
		}
	}

	.view-all-doc {
		text-decoration: underline;
		font-weight: $headings-font-weight;
		font-family: $headings-font-family;
		color: $primary;

		&:hover {
			text-decoration: none;
		}
	}

	.dropdown-item {
		font-weight: $headings-font-weight;

		&:hover {
			text-decoration: underline;
			color: $primary;
		}
	}
}

.nav-border {
	border-bottom: $border;
}


.icon-nav-arrow {
	height: $spacer * 1.2;
	width: $spacer * 1.2;
}

.nav-link::after {
	display: none;
}

.navbar-btn{
	height:$spacer * 2.5;
}

.mobile-bottom-nav{
	display: none;
}

.fadeIn{
	animation: fadeIn 0.5s;
}
@keyframes fadeIn {
   0% {
      
      transform: translateX(-100%);
   }
   100% {
     
      transform: translateX(0);
   }
}

.nav-top-link{
	font-weight:900;
}