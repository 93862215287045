//////////////////////////////////////////////////
// Site config
//////////////////////////////////////////////////

// Config variables that affect Bootstrap styles.
// Update these variables as needed.
$enable-negative-margins: true;
$enable-shadows: true;

// Custom config variables.
// Update these variables as needed.
$enable-navbar-fixed: false;

//////////////////////////////////////////////////
// Color system
//////////////////////////////////////////////////

// Brand Colours 
// Update these variables as needed.
$primary: #0074B3;
$secondary: #02707A;
$tertiary: #E5BE4E;
$quaternary: #E1684D;
$quinary: #8FC9C2;

// Contextual Colours
// Update these variables as needed.
$success: #68B992;
$info: #5FB3C5;
$warning: #E8B364;
$danger: #DC7338;

// Helper Colours
// Update these variables as needed.
$light: #FAFAFA;
$dark: #484849;
$gray: #AAAAAB;

// Brand Primary Colour Shades
$primary-500: $primary;


// Brand Secondary Colour Shades
$secondary-500: $secondary;

// Brand Tertiary Colour Shades
$tertiary-light: #FCF5E3;
$tertiary-500: $tertiary;
$tertiary-medium: #F7E9C1;

// Brand Quaternary Colour Shades
$quaternary-light: #FEF0EC;
$quaternary-500: $quaternary;
$quaternary-medium: #F9E0DB;


// Brand Quinary Colour Shades
$quinary-light: #E8F5F3;
$quinary-500: $quinary;
$quinary-medium: #D1E8E6;

// Contextual Success Colour Shades
$success-100: #E1F1E9;
$success-200: #C3E3D3;
$success-300: #A4D5BE;
$success-400: #86C7A8;
$success-500: $success;
$success-600: #539475;
$success-700: #3E6F58;
$success-800: #2A4A3A;
$success-900: #15251D;

// Contextual Info Colour Shades
$info-100: #DFF0F3;
$info-200: #BFE0E8;
$info-300: #9FD1DC;
$info-400: #7FC2D1;
$info-500: $info;
$info-600: #4C8F9E;
$info-700: #396B76;
$info-800: #26474F;
$info-900: #132427;

// Contextual Warning Colour Shades
$warning-100: #FAF0E0;
$warning-200: #F6E1C1;
$warning-300: #F1D1A2;
$warning-400: #ECC283;
$warning-500: $warning;
$warning-600: #B98F50;
$warning-700: #8B6B3C;
$warning-800: #5D4828;
$warning-900: #2E2414;

// Contextual Danger Colour Shades
$danger-100: #F8E3D7;
$danger-200: #F1C7AF;
$danger-300: #EAAB88;
$danger-400: #E38F60;
$danger-500: $danger;
$danger-600: #B05C2D;
$danger-700: #844522;
$danger-800: #582E16;
$danger-900: #2C170B;

// Grays Colour Shades
$gray-100: #F4F4F4;
$gray-200: #E5E4E4;
$gray-300: #CCCCCD;
$gray-400: #BBBBBC;
$gray-500: $gray;
$gray-600: #888889;
$gray-700: #666667;
$gray-800: #484849;
$gray-900: #222222;

// Helper Colours
$white: #fff;
$black: #000;

// Theme Colours
// Helper classes are generated using the colours defined in $theme-colors.
// Add and remove colours as appropriate for the system based on the variables defined above.
$theme-colors: (
	'primary': $primary,
	'secondary': $secondary,
	'tertiary':$tertiary,
	'quinary': $quinary,
	'quaternary' : $quaternary,
	'tertiary-light':$tertiary-light,
	'quinary-light': $quinary-light,
	'quaternary-light' : $quaternary-light,
	'tertiary-medium':$tertiary-medium,
	'quinary-medium': $quinary-medium,
	'quaternary-medium' : $quaternary-medium,
	'success': $success,
	'info': $info,
	'warning': $warning,
	'danger': $danger,
	'dark': $dark,
	'gray-100':$gray-100,
	'gray-200':$gray-200,
	'gray-300':$gray-300,
	'gray-400':$gray-400,
	'gray-800':$gray-800,
	'gray-700':$gray-700,
	'white': $white,
	'light': $light,

);

//////////////////////////////////////////////////
// Transitions
//////////////////////////////////////////////////

$transition-duration-base: 175ms;
$transition-duration-long: 300ms;
$transition-bezier: cubic-bezier(0.4, 0, 0.2, 1);

//////////////////////////////////////////////////
// Spacing
//////////////////////////////////////////////////

$spacer: 1rem;
$spacers: (
	0: 0,
	1: $spacer * 0.25,
	2: $spacer * 0.5,
	3: $spacer,
	4: $spacer * 2,
	5: $spacer * 3,
	6: $spacer * 4,
	7: $spacer * 5,

);

//////////////////////////////////////////////////
// Body
//////////////////////////////////////////////////

$body-color: $dark;
$body-bg: $white;

//////////////////////////////////////////////////
// Links
//////////////////////////////////////////////////

$link-color: $primary;
$link-hover-color: darken($link-color, 15%);
$text-muted: rgba($body-color, 0.4);

//////////////////////////////////////////////////
// Grid breakpoints
//////////////////////////////////////////////////

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
);

//////////////////////////////////////////////////
// Grid containers
//////////////////////////////////////////////////

$container-max-widths: (
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
);

//////////////////////////////////////////////////
// Grid columns
//////////////////////////////////////////////////

$grid-gutter-width: $spacer * 2;

//////////////////////////////////////////////////
// Components
//////////////////////////////////////////////////

$line-height-sm: 1.2;

$border-width: 1px;
$border-color: $gray-400;
$border: $border-width solid $border-color;

$border-radius: 0.25rem;
$border-radius-lg: 0.5rem;
$border-radius-sm: 0.2rem;
$border-radius-0: 0rem;

$brand-aspect-ratio: math.div(138, 316);

$disabled-opacity: 0.25;

$icon-size: 1.5rem;

//////////////////////////////////////////////////
// Fonts
//////////////////////////////////////////////////

$font-family-base: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$sans-serif-family: $font-family-base;

$font-size-root: 18px;
$font-size-root-sm: 16px;
$font-size-base: 1rem;

$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;
$h6-font-size: $font-size-base;

$headings-font-family: 'freight-text-pro', 'Helvetica Neue', Helvetica, Arial, serif;
$headings-font-weight: 900;
$headings-margin-bottom: 0.75rem;
$headings-line-height: 1.2;

$display-font-sizes: (
	1: 3.5rem,
	2: 3rem,
	3: 2.5rem,
	4: 2rem,
	5: 1.5rem,
	6: 1.25rem
);

$display-font-weight: 700;

$display-line-height: 1.1;

$lead-font-size: (
	$font-size-base * 1.25
);
$lead-font-weight: 400;

$max-read-width: 40em;

$small-font-size: 85%;

//////////////////////////////////////////////////
// Tables
//////////////////////////////////////////////////

$table-hover-bg: mix($primary, $gray-100, 2%);

$table-border-width: 1px;

$table-head-cell-padding-y: 0.3rem;

$table-highlight-bg: lighten($primary, 36%);
$table-highlight-bg-hover: darken(white, 4%);
$table-highlight-border: $table-highlight-bg-hover;

$table-icon-padding-y: 0.62rem;
$table-icon-padding-x: 0.75rem;

$table-cell-padding-sm: 0.5rem;

//////////////////////////////////////////////////
// Buttons
//////////////////////////////////////////////////

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
$btn-padding-x: 2rem;

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 1.5rem;
$btn-padding-x-lg: 2.5rem;

$btn-border-radius: 0;

$input-btn-icon-padding: 0.75em;

$btn-font-weight: 700;

$btn-box-shadow: unset;
$btn-hover-box-shadow: unset;
$btn-active-box-shadow: unset;

$btn-disabled-opacity: 0.25;

//////////////////////////////////////////////////
// Tabs
//////////////////////////////////////////////////

$tab-padding-x: $input-btn-padding-x;
$tab-padding-y: $input-btn-padding-y;

$tab-colour: $link-color;

$tab-hover-color: $link-hover-color;
$tab-hover-bg: rgba($link-color, 0.1);

$tab-active-color: $body-color;
$tab-active-bg: transparent;

//////////////////////////////////////////////////
// Forms
//////////////////////////////////////////////////

$form-group-margin-bottom: 1.75rem;

$input-border-color: $gray-400;

$input-label-font-weight: 700;

$input-btn-focus-color: rgba($primary, .25);
$input-focus-border-color: $primary;

$input-placeholder-color: $gray-400;

$input-btn-line-height: $line-height-base;
$input-btn-border-width: $border-width;

$input-height-border: $input-btn-border-width * 2;
$input-height-inner: (
	$font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2
);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});

$input-switch-handle-bg: $primary;
$input-switch-handle-color: #fff;

$custom-control-indicator-bg: transparent;
$custom-control-indicator-border: $input-border-color;

$custom-control-indicator-checked-bg: $primary;

$custom-control-indicator-active-bg: rgba($primary, 0.35);
$custom-control-indicator-active-border: $custom-control-indicator-active-bg;


$tedxtarea-min-height: 10rem;

//////////////////////////////////////////////////
// Dropdowns
//////////////////////////////////////////////////

$dropdown-bg: $white;
$dropdown-link-hover-bg: $white;

$dropdown-link-color: $gray-900;

$dropdown-link-active-color: $dropdown-link-color;
$dropdown-link-active-bg: $dropdown-bg;

$dropdown-item-padding-y: 0rem;
$dropdown-item-padding-x: 0rem;


//////////////////////////////////////////////////
// Z-index
//////////////////////////////////////////////////

//////////////////////////////////////////////////
// Navs
//////////////////////////////////////////////////

//////////////////////////////////////////////////
// Navbar
//////////////////////////////////////////////////

$navbar-width-md: 18rem;
$navbar-width-md-collapsed: 4rem;
$navbar-height: 3.5rem;
$navbar-sub-height: 2.5rem;

$navbar-side-width: 20rem;
$navbar-side-width-lg: 25rem;
$navbar-side-width-sm: 15rem;
$navbar-light-color: $dark;
$navbar-light-active-color: $dark;
$navbar-dark-color: #fff;
$navbar-dark-hover-color: #fff;

$nav-collapse-bg: #fff;
$nav-collapse-color: $link-color;

$navbar-shadow-base: 0 3px 6px 0 rgba(#000, 0.16);

$nav-link-padding-x: 0.83rem;
$nav-link-padding-y: 1rem;

$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: $grid-gutter-width * .5;
$navbar-toggler-border-radius: 0;

//////////////////////////////////////////////////
// Page Hero
//////////////////////////////////////////////////

$page-hero-spacer-top: $grid-gutter-width;
$page-hero-spacer-bottom: $grid-gutter-width;
$page-hero-spacer-top-sm: $grid-gutter-width * 0.5;
$page-hero-spacer-bottom-sm: $grid-gutter-width * 0.5;
$page-hero-spacer-top-lg: $grid-gutter-width * 1.5;
$page-hero-spacer-bottom-lg: $grid-gutter-width * 1.5;

//////////////////////////////////////////////////
// Page Footer
//////////////////////////////////////////////////

$page-footer-bg: $body-bg;
$page-footer-color: #999;

$page-footer-spacer-top: $grid-gutter-width * 2;
$page-footer-spacer-bottom: $grid-gutter-width * 2;
$page-footer-spacer-top-sm: $grid-gutter-width;
$page-footer-spacer-bottom-sm: $grid-gutter-width ;
$page-footer-spacer-top-lg: $grid-gutter-width * 3;
$page-footer-spacer-bottom-lg: $grid-gutter-width * 3;

//////////////////////////////////////////////////
// Cards
//////////////////////////////////////////////////

$card-spacer-x: $grid-gutter-width;
$card-spacer-y: $grid-gutter-width;

$card-border-width: 1px;
$card-border-radius: 0;

$card-inner-border-radius: 0;

$card-shadow-base: 0 6px 24px 0 rgba(black, 0.16);
$card-shadow-hover: 0 6px 12px 0 rgba(black, 0.3);
$card-shadow-active: 0 2px 4px 0 rgba(black, 0.3);
$card-height: 100%;

//////////////////////////////////////////////////
// Modals
//////////////////////////////////////////////////

$modal-inner-padding: $grid-gutter-width;

//////////////////////////////////////////////////
// Datepicker
//////////////////////////////////////////////////

$datepicker-spacer: $input-btn-padding-x;
$datepicker-prev-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg fill='#{$link-color}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z'/%3E%3C/svg%3E"), '#', '%23');
$datepicker-next-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg fill='#{$link-color}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z'/%3E%3C/svg%3E"), '#', '%23');


//////////////////////////////////////////////////
// Toast
//////////////////////////////////////////////////

$toast-inner-padding: math.div($grid-gutter-width, 2);

$toast-border-radius: $border-radius;

$toast-shadow-base: $card-shadow-base;
$toast-shadow-active: $card-shadow-active;

//////////////////////////////////////////////////
// Pincode
//////////////////////////////////////////////////

$pincode-number-cols: 3;
$pincode-number-size: $grid-gutter-width * 1.5;
$pincode-number-spacer: math.div($grid-gutter-width, 6);

//////////////////////////////////////////////////
// Print
//////////////////////////////////////////////////

$print-page-size: a4;

//////////////////////////////////////////////////
// Project specific variables
// Add your custom variables here
//////////////////////////////////////////////////
/// // The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $primary;

//set input btn and btn font to proxima-nova
$input-btn-font-family: $headings-font-family;

html {
	font-size: $font-size-root-sm;

	@include media-breakpoint-up(lg) {
		font-size: $font-size-root;
	}
}

// nav-variables
$nav-pills-border-radius: $border-radius-0;
$nav-pills-link-active-color: $black;
$nav-pills-link-active-bg: $quinary-light;
// nav-variables

$table-striped-bg: $light ;

//accordion variables
$accordion-button-bg: transparent;
$accordion-bg: transparent;
$accordion-button-active-bg: transparent;
$accordion-button-padding-x: 0rem;
$accordion-body-padding-x: 0rem;
$accordion-button-active-color: $dark;

$blockquote-font-size: $spacer;
$navbar-nav-link-padding-x: $spacer;
$modal-content-border-radius:0rem;
$pagination-border-color: none;
$pagination-hover-bg: none;
$pagination-hover-color: $primary;
$pagination-color: $dark;
$pagination-bg: transparent;
$accordion-button-focus-box-shadow:none;
