.alert-info{
	color: $info-800;
}

.alert-warning{
	color: $warning-800;
}

.alert-success{
	color: $success-800;
}

.alert-danger{
	color: $danger-800;
}