//////////////////////////////////////////////////
// Navbar
//////////////////////////////////////////////////

@include media-breakpoint-down(lg) {
	.nav-open {
		overflow: hidden;
	}
}

.navbar-wrapper {
	@if $enable-navbar-fixed {
		@extend .fixed-top;
	}
}

.navbar {
	@if $enable-navbar-fixed {
		height: $navbar-height;
	}

	@include media-breakpoint-up(xl) {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.subnavbar {
	.navbar-nav .nav-link {
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;
	}

	.navbar-nav .no-pointer {
		pointer-events: none;
	}
}

	.nav-link.dropdown-toggle {
		position: relative;
		display: flex !important;
		justify-content: space-between;
		align-items: center;
		&::after {
			content: "";
			display: block;
			border: none;
			height: 20px;
			transform: rotate(90deg);
			width: 20px;
			background: url('/assets/icons/icon-chevron-right-black.svg') center center no-repeat;
		}
	}

//////////////////////////////////////////////////
// Collapse Container
//////////////////////////////////////////////////

@include media-breakpoint-up(lg) {
	.navbar-collapse {
		transform: none !important;
	}
}

@include media-breakpoint-down(lg) {
	.navbar-collapse {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		box-shadow: $box-shadow;
		margin-right: $spacer * 2;
		z-index: $zindex-fixed;
		overflow: auto;
		background-color: $nav-collapse-bg;
		transition: all $transition-duration-base $transition-bezier;

		&.collapse:not(.show) {
			display: block;
			visibility: hidden;
			transform: translateX(-100%);
			pointer-events: none;
		}
		.sub-navbar-collapse {
			position: fixed !important;
			top: 60px;
			right: 0;
			bottom: 0;
			left: 0;
			margin-right: $spacer * 2;
			z-index: calc($zindex-fixed + 1);
			overflow: auto;
			background-color: $nav-collapse-bg;
			transition: all $transition-duration-base $transition-bezier;
			&.collapsed {
				display: block;
				visibility: hidden;
				transform: translateX(-100%);
				pointer-events: none;
			}
		}
	}
	.nav-top-link.has-sub-menu {
		position: relative;
		display: flex !important;
		justify-content: space-between;
		align-items: center;
		&::after {
			content: "";
			display: block;
			height: 18px;
			width: 18px;
			background: url('/assets/icons/icon-chevron-right-black.svg') center center no-repeat;
		}
	}

	.dropdown-menu {
		box-shadow: unset;
		> li {
			padding-left: $spacer * 2;
		}
	}
}

//////////////////////////////////////////////////
// Mega Nav
//////////////////////////////////////////////////

@include media-breakpoint-up(xl) {
	.nav-mega-open {
		overflow: hidden;
	}

	.navbar-mega-toggler {
		position: relative;
		z-index: $zindex-fixed + 1;
	}

	.navbar-mega-collapse {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding: $grid-gutter-width * 2;
		z-index: $zindex-fixed;
		overflow: auto;
		background-color: #fff;

		&.collapse {
			display: none;
		}

		.navbar-nav {
			flex-wrap: wrap;
		}

		.nav-item-heading {
			width: 100%;
		}

		.nav-heading {
			padding-bottom: 0;
		}
	}
}

@include media-breakpoint-down(xl) {
	.navbar-mega-collapse {
		display: block !important;
		transform: none !important;
	}
}

//////////////////////////////////////////////////
// Nav Links
//////////////////////////////////////////////////

.nav-heading {
	display: block;
	padding: $nav-link-padding-y $nav-link-padding-x;
	font-weight: $headings-font-weight;
}

.navbar-nav .nav-link {
	display: block;
	position: relative;
	padding: $nav-link-padding-y $nav-link-padding-x;
	border: 0;
	cursor: pointer;

	@include media-breakpoint-up(xl) {
		display: inline-block;

		.navbar-expand-xl & {
			padding: $nav-link-padding-y $nav-link-padding-x;
		}
	}
}

//////////////////////////////////////////////////
// Toggler
//////////////////////////////////////////////////

.navbar-toggler {
	border: 0;

	&:focus {
		outline: none;
	}
}

//////////////////////////////////////////////////
// Nav brands
//////////////////////////////////////////////////

.navbar-brand {
	padding: 0;
	line-height: 1;

	svg {
		width: 100%;
	}
}

.nav-item-brand {
	display: inline-block;
	margin-left: $nav-link-padding-x;

	svg {
		@include brand-size(9rem);
	}
}

.navbar-icon {
	width: $spacer * 1.5;
	height: $spacer * 1.5;
}

.navbar-logo {
	@include brand-size(15rem);
	margin-bottom: $grid-gutter-width;
}