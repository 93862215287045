.circle {
	min-width: $spacer * 1.75;
	min-height: $spacer * 1.75;
	max-width: $spacer * 1.75;
	max-height: $spacer * 1.75;
	line-height: $spacer * 1.25;
	border-radius: 50%;
	text-align: center;
	background-color: $quinary-500;
	color: $white;
	margin-right: $spacer * 0.5;
	font-family: $headings-font-family;
	vertical-align: middle;
}

.icon-sm {
	min-height: $spacer;
	min-width: $spacer;
	max-height: $spacer; 
	max-width: $spacer; 
}
.icon-md {
	min-height: $spacer * 1.5;
	min-width: $spacer * 1.5;
	max-height: $spacer * 1.5; 
	max-width: $spacer * 1.5; 
}
.icon-lg {
	min-height: $spacer * 2;
	min-width: $spacer * 2;
	max-height: $spacer * 2; 
	max-width: $spacer * 2; 
}