.tag {
	font-family: $font-family-base;
	font-weight: $lead-font-weight;
	border: $border;
	color: $dark;
	padding: $spacer * 0.5;
}

.table thead th{
font-weight:inherit;
text-transform: inherit;
}

.table-mobile{
display:none;
}

.min-width-10{
min-width:10rem;	
}


@include media-breakpoint-down(md){ 
	.table-mobile{
display:block;
}

.table-desktop{
display:none;
}
}