
.blockquote::before{
	content: url(../../assets/icons/icon-quote.svg);
}






