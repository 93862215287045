//////////////////////////////////////////////////
// Headings
//////////////////////////////////////////////////

.lined-heading {
  margin-bottom: $spacer * 2;
  padding-bottom: $spacer * .5;
  border-bottom: $border;
  font-size: 0.9rem;
  text-transform: uppercase;
}

//////////////////////////////////////////////////
// Style helpers
//////////////////////////////////////////////////

.max-read-width {
  max-width: $max-read-width;
}

.strong {
	font-weight: bolder;
}

.em {
	font-style: italic;
}

.large {
	font-size: 125%;
}

.xlarge {
	font-size: 150%;
}

.text-break {
  word-break: break-all;
}

.text-pre-line {
  white-space: pre-line;
}

.reduced-em {
  opacity: 0.6;
}

.text-regular {
  font-family: var(--#{$prefix}body-font-family);
}