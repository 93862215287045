.form-check{
	top: $spacer;
	right: $spacer;
}
.form-check-input:checked[type=checkbox] {
    background-image: none;
}

.form-check-input:checked {
    background-color: $quinary;
    border-color: $secondary;
}
.form-check-input[type=checkbox] {
    border-radius: 0.5rem;
}

.form-select{
    font-family: $font-family-base;
    font-weight: $lead-font-weight;
    font-style: italic;
    border-radius:0;
    color: $dark;
}

::placeholder{
    font-family: $font-family-base;
    font-style: italic;
}

.form-control{
      font-style: normal;
    font-family: $font-family-base;

}

.form-select{
    font-family: $font-family-base;
    font-weight: $lead-font-weight;
    font-style: italic;
    border-radius:0;
    color: $dark;
}
    
@include media-breakpoint-down(md){ 
    .form-select{
        min-width:100%;
    }

}