.btn-outline-primary:hover{
	 background-color: rgba(0, 116, 179, 0.15);
	 color:$primary;
}

.btn-white:hover{
	background-color: $primary;
	 color:$white;
	 border:$primary solid 1px;
}

