footer a {
	color: $body-color;


	&:hover {
		color: $primary;
	}
}

.footer-links {
	display: none;
}