.spinner {
  display: inline-block;
  width: 1.715em;
	height: 1.715em;
	padding: 0.25em;
	line-height: 1;
	vertical-align: middle;
	animation: spin 1s linear infinite;
	
	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		border: 2px solid transparent;
		border-top-color: currentColor;
		border-radius: 50%;
	}
}
.spinner-large {
	width: 60px !important;
	height: 60px !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
