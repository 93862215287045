.accordion-button::after{
	content:url(../../assets/icons/icon-plus.svg);
	background-image: unset;
}

.accordion-button:not(.collapsed)::after {
    background-image: unset;
    transform: unset;
	content:url(../../assets/icons/icon-minus.svg);
}

.accordion-button:not(.collapsed){
	font-weight: $display-font-weight;
	box-shadow: none;
}

.bg-quinary-light{
	.accordion-button:not(.collapsed){
	color:$secondary;
}
}
.accordion-header{
	font-family: $font-family-base;
	font-weight: $lead-font-weight;
}

