//////////////////////////////////////////////////
// Buttons
//////////////////////////////////////////////////

.btn {
	cursor: pointer;
	vertical-align: middle;
	.btn-content {
		vertical-align: middle;
	}
	&.disabled,
	&:disabled {
		cursor: default;
		pointer-events: none;
	}
}

@each $color, $value in $theme-colors {
	.btn-#{$color} {
		box-shadow: $btn-box-shadow;

		&:hover {
			box-shadow: $btn-hover-box-shadow;
		}

		&:active {
			box-shadow: $btn-active-box-shadow;
		}
	}
}

// Outline buttons
[class*='btn-outline-'] {
	&:active,
	&.active {
		&:not([disabled]):not(.disabled) {
			color: #fff;
		}
	}
}

.btn-outline-light {
	&:hover,
	&:active,
	&.active {
		&:not([disabled]):not(.disabled) {
			color: $link-color;
		}
	}
}

//////////////////////////////////////////////////
// Icon buttons
//////////////////////////////////////////////////

.btn-icon {
	padding: $input-btn-icon-padding;
	line-height: 1;

	.icon {
		display: block;
	}
}

@each $color, $value in $theme-colors {
	.btn-icon-#{$color} {
		color: $value;
		@extend .btn-link;
		padding-left: $spacer * 0.5;
		padding-right: $spacer * 0.5;

		&:active,
		&:hover, 
		&:active *,
		&:hover * {
			color: darken($value, 7.5%); 
			fill:  darken($value, 7.5%); 
		}
	}
}

//////////////////////////////////////////////////
// Table buttons
//////////////////////////////////////////////////

.btn-table {
	padding: $input-btn-padding-y $input-btn-padding-x;
}

.btn-pd-fix {
	padding: 0 $input-btn-icon-padding 0 $input-btn-icon-padding;
}

//////////////////////////////////////////////////
// Button Group
//////////////////////////////////////////////////

.btn-group {
	a:not([href]):not([tabindex]) {
		color: $secondary;

		&:hover,
		&.active {
			color: #fff;
		}

		&:hover {
			z-index: 3;
		}
	}
}

.btn-tabs {
	max-width: 100%;
	overflow-x: auto;
}

.arrow-link {
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	white-space: nowrap;

	&-label {
		flex: 1 1 auto;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	svg {
		flex: 0 0 auto;
		width: 1em;
		height: 1em;
		margin-left: 0.25em;
		transition: transform $transition-duration-base $transition-bezier;
	}

	&:hover, &:focus {
		svg {
			transform: translate(25%, 0);
		}
	}
}
//================================================
//
// Ant Design Buttons
//
//================================================

.btn-loading {
	position: relative;

	&,
	&:disabled,
	&.disabled {
		opacity: 0.65;
	}
}

.btn-content {
	display: inline-flex;
	align-items: center;
	transition: all $transition-duration-base $transition-bezier;
}
